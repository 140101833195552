
export default function Layout({ children }) {

  return (
    <>
      <div />
        {children}
      <div />
    </>
  )
}
