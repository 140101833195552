import { setCookie, getCookie } from 'cookies-next';
import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Input, Container } from "reactstrap";
import Link from 'next/link';
import axios from "axios";
import logo from '../public/admin/logo.png';
import { useRouter } from 'next/router';
import Image from 'next/image';
import DefaultLayout from '../components/layouts/DefaultLayout';
import FlashState from "../components/Common/FlashState";
import FlashMessages from "../components/Common/FlashMessages";
import Head from 'next/head';

function Login({ data, meta }) {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [emailErr, setEmailErr] = useState(false);
    const [passwordErr, setPasswordErr] = useState(false);
    const [otherErr, setOtherErr] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const router = useRouter();

    useEffect(() => {
        if(data) {
            let prefix = '';
            if(data.role_id === 1) {
                prefix = process.env.NEXT_PUBLIC_ADMIN_ROUTE;

            } else if(data.role_id === 2){
                prefix = process.env.NEXT_PUBLIC_AGENCY_ROUTE;
            }

            router.push({pathname: '/' + prefix + '/dashboard'})
        }

        setTimeout(() => { setShowLoader(false) }, 1000);

    }, [showLoader, data, router]);

    const submitLoginForm = async () => {
        setLoading(true);

        await axios.post(process.env.NEXT_PUBLIC_API_URL+"login", {
            email: email, password: password
        })
        .then((response) => {
            setLoading(false);

            if (response.status === 200) {
                FlashState.set('message', 'Logged in Successfully!!');
                FlashState.set('type', 'success');
                setCookie('token', response.data.data.access_token);
                setCookie('user_role', response.data.data.role_id);

                if(response.data.data.role_id === 4) {
                    router.push({pathname: '/users/dashboard'})

                } else if(response.data.data.role_id === 2) {
                    const businessDetail = response.data.data.business_detail;

                    if(!businessDetail.website_address && !businessDetail.website_cover_image &&
                        !businessDetail.website_logo && !businessDetail.description) {
                            router.push({
                                pathname: '/' + process.env.NEXT_PUBLIC_AGENCY_ROUTE + '/details',
                                state: {business_id: businessDetail.id}
                            });

                    } else {
                        router.push({
                            pathname: '/' + process.env.NEXT_PUBLIC_AGENCY_ROUTE + '/dashboard',
                            state: {business_id: businessDetail.id}
                        });
                    }

                } else {
                    router.push({
                        pathname: '/' + process.env.NEXT_PUBLIC_ADMIN_ROUTE + '/dashboard',
                    });
                }
            }

        }, (error) => {
            setLoading(false);

            if (error.response.status === 412) {
                setEmailErr(error.response.data.validation_error.email);
                setPasswordErr(error.response.data.validation_error.password);
            }

            setOtherErr(error.response.data.message);
        });
    }

    const onChangeEmail = (e) => {
        setEmail(e.target.value);
        setEmailErr(''); setOtherErr('');
    }

    const onChangePassword = (e) => {
        setPassword(e.target.value);
        setPasswordErr(''); setOtherErr('');
    }

    return (
        <div className="login-page">
            <Head>
                <meta name="keywords" content={meta ? meta.keywords : ''} />
                <title>{meta ? meta.title : ''}</title>
                <meta name="description" content={meta ? meta.description : ''} />
                <link rel="canonical" href={process.env.NEXT_PUBLIC_APP_URL + router.asPath} />
            </Head>
            <div id="spinner" className="spinner_req_review"
                style={{"display": showLoader ? "block" : "none"}}>
                <div className="lds-spinner">
                    <div></div><div></div><div></div>
                    <div></div><div></div><div></div>
                    <div></div><div></div><div></div>
                    <div></div><div></div><div></div>
                </div>
            </div>
            <div className="logo_section">
                <Link href="/"><a><Image src={logo} alt="logo" /></a></Link>
            </div>
            <Container>
                <FlashMessages />
                <div className="form_Section">
                    <h2 className="login-title">Sign In</h2>
                    <Form autoComplete="off">
                        <FormGroup>
                            <Input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={email}
                                onChange={onChangeEmail}
                                className="input_text"/>
                            <span className="text-danger">{emailErr}</span>
                        </FormGroup>
                        <FormGroup>
                            <Input
                                type="password"
                                name="password"
                                placeholder="Password"
                                value={password}
                                onChange={onChangePassword}
                                className="input_text"/>
                            <span className="text-danger">{passwordErr}</span>
                        </FormGroup>
                        <p className="text-danger">{otherErr}</p>
                        <Button
                            className="primary-btn"
                            onClick={submitLoginForm}>
                            <span>Sign In</span>
                            {
                                loading
                                ? (<span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span>) 
                                : ( <span></span> )
                            }
                        </Button>
                    </Form>
                    <p className="already_text">
                        <Link href="/forget-password"><a>Forget Password?</a></Link>
                    </p>
                    <p className="already_text">
                        {"Don't have an account?"} <Link href="/signup"><a>Sign Up</a></Link>
                    </p>
                </div>
            </Container>
        </div>
    )
}
  
// This gets called on every request
export async function getServerSideProps({ req, res }) {
    let token = getCookie('token', { req, res});

    if(token != null) {
        try {
            const base64 = require('base-64');
            const response = await axios.get(process.env.NEXT_PUBLIC_API_URL+'user', { headers: {
                "Authorization" : 'Bearer '+ base64.decode(token),  "Accept": "application/json" }
            });


            const metaRes = await axios.get(process.env.NEXT_PUBLIC_API_URL+'metas?path=/awards', { headers: {
                "Accept": "application/json" }
            });

            return { props: { data: response.data, meta: metaRes.data.data } }

        } catch (err) {
            const data = false;
            const meta = false;
            return { props: { data, meta } };
        }

    } else {
        const data = false;
         const metaRes = await axios.get(process.env.NEXT_PUBLIC_API_URL+'metas?path=/login', { headers: {
            "Accept": "application/json" }
        });
        return { props: { data, meta: metaRes.data.data } };
    }
}

Login.Layout = DefaultLayout;
export default Login
