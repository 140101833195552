import React, { Component } from 'react';
import FlashState from "./FlashState";

export default class Signin extends Component {
  constructor(props) {
	super(props);
	this.state = {
	  type: "success",
	  message: "",
	};
  }

  componentDidMount() {
	this.setState({ message: FlashState.get('message') });
	this.setState({ type: FlashState.get('type') });

	setTimeout(() => {
		this.setState({ message: "" });
	}, 5000);
  }

  render() {
	if(this.state.message) {
		return (<div className={`flash flash-${this.state.type}`}><p>{this.state.message} <span>x</span></p></div>);
	}

	return (<div className=""></div>);
  }
}

